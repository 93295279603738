<template>
  <v-layout class="mb-4 pb-4" wrap>
    <v-flex xs12>
      <Title title="Direcciones predefinidas" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-data-table
          item-key="id"
          :headers="headers"
          :items="items"
          :loading="loading"
          rows-per-page-text="items por pagina"
          no-data-text="No hay direcciones ingresadas"
          class="elevation-0"
          hide-actions
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td>
                {{ props.item.id }}
              </td>

              <td>
                <span v-html="props.item.name" />
              </td>
              <td>
                <span v-html="props.item.comuna.name" />
              </td>
              <td>
                <v-chip v-if="props.item.active" color="success" text-color="white" label small>
                  <v-icon left>check</v-icon> Activo
                </v-chip>
                <v-chip v-else color="error" text-color="white" label small>
                  <v-icon left>clear</v-icon> Inactivo
                </v-chip>
              </td>
              <td>
                <v-icon
                  small
                  class="mr-2"
                  @click="
                    $router.push({
                      name: 'settingsAddressEdit',
                      params: { id: props.item.id }
                    })
                  "
                >
                  edit
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
      <v-btn fab dark fixed bottom right color="success" @click="newItem">
        <v-icon>add</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { CONFIG } from '../../config'
import Title from '../useful/title.vue'

export default {
  name: 'SettingCategories',
  components: {
    Title
  },
  data() {
    return {
      loading: false,
      items: [],
      types: [
        { value: 1, text: 'Web' },
        { value: 2, text: 'Paginas de contenido' },
        { value: 3, text: 'Categoria' },
        { value: 4, text: 'Url' }
      ],
      headers: [
        {
          text: 'Nº',
          align: 'left',
          sortable: false
        },
        {
          text: 'Nombre',
          align: 'left',
          sortable: false
        },
        {
          text: 'Comuna',
          align: 'left',
          sortable: false
        },
        {
          text: 'Estado',
          align: 'left',
          sortable: false
        },
        {
          text: '',
          align: 'left',
          sortable: false
        }
      ]
    }
  },
  mounted() {
    this.getCMS()
  },
  methods: {
    async getCMS() {
      try {
        const res = await this.$http.get(`${CONFIG}/address`)
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    newItem() {
      this.$router.push({ name: 'settingsAddressEdit', params: { id: 'new' } })
    }
  }
}
</script>
